<template>
        <base-page>
        
        <el-card style='margin:5px 0px 5px 0px'>
            <div class="filtre">
                
                <el-form @submit.prevent.native='cautare_dupa_cod()'>
                    <el-row :gutter="20">
                        <el-col :md='2' :xs='4'>
                            <el-button type='primary' size='small' icon="el-icon-s-promotion" style='margin-top:5px' @click='paste_and_search()'></el-button>
                        </el-col>
                        <el-col :md='8' :xs='16'>
                            <el-form-item label='' >
                                <el-input v-model="CautareCod" id='CautareCod' size='small' placeholder="Cautare dupa cod..." clearable/>
                            </el-form-item>
                        </el-col>
                        <el-col :md='2' :xs='4'>
                            <el-button type='primary' size='small' icon="el-icon-search" style='margin-top:5px' @click='cautare_dupa_cod()'></el-button>
                        </el-col>

                    </el-row>
                </el-form>

                <h1>Genereaza licenta</h1>
                
                
                <div class='rezultat' v-if="CodGenerat != ''">
                    <input type='text' :value='CodGenerat' id='cod-generat' style='width: 230px; text-align:center; margin-bottom:10px; font-size:14px; font-weight: bold;'/> <br/>
                    <div class="rand">
                        <div class="col-1">
                            <div>Locatie: {{get_nume_locatie()}}</div>
                            <div>Statie: {{get_nume_statie()}}</div>
                            <div>Valabilitate: {{Filters.DataExpirarii}}</div>
                        </div>
                        <div  class="col-2">
                            <el-button type='success' @click="copiaza()" size="mini">Copiaza</el-button>
                        </div>
                    </div>
                </div>
                <el-form @submit.prevent.native='genereaza_cod()' >
                {{ AdaugareDataExtinsaEnabled }}
                            <el-form-item label='Client' >

                                <v-select :options="Info.clienti " label="Nume" 
                                    v-model='Filters.IdClient'  :reduce="x => x.Id" @input='on_change_client()' :disabled='filtru_clienti_disabled'>
                                </v-select>
                             
                                <el-button v-if="this.Filters.IdClient != '-1'" type="warning" size='small' icon="el-icon-right"  @click="alege_client_selectat()" >Seteaza client</el-button>
                            </el-form-item>

                            <div v-if="!hideInfoClienti">
                                <el-form-item label='Locatie' >
                                    <v-select :options="Info.locatii" label="Nume" v-model='Filters.IdLocatie'  :reduce="x => x.Id" @input='on_change_locatie()'></v-select>
                                </el-form-item>

                                <el-button v-if="Info.NrTaskuriDeschise > 0" type="warning" style="margin-top: 5px; font-weight: bold" size="mini" @click="show_taskuri_dialog()">
                                    <i class="el-icon-warning-outline"></i>
                                    Locatia are {{Info.NrTaskuriDeschise}} taskuri deschise!
                                </el-button>

                                <el-form-item label='Statie' >
                                    <v-select :options="Info.statii" label="Nume" v-model='Filters.IdStatie'  :reduce="x => x.Id" @input='on_change_statie()'>
                                        <template #option="item">
                                            <div>{{ item.Nume }}</div>
                                            <div style=" font-size: 14px; font-weight:bold; border-bottom: 1px solid #DCDCDC;">{{ item.Cod }}</div>
                                        </template>
                                    </v-select>

                                    <span>
                                        Cod statie: <span style='font-weight: bold;'>{{StatieSelectata.Cod}}</span>
                                    </span>
                                    <br/>
                                    
                                    <el-tag v-if="StatieSelectata.OperatInFacturier=='1'" style="font-weight: bold !important; margin-right: 5px"  type="success">Operat</el-tag>
                                    <el-tag v-if="StatieSelectata.OperatInFacturier=='0'" style="font-weight: bold !important; margin-right: 5px"  type="danger">Nevalidat</el-tag>

                                    <el-tag v-if="StatieSelectata.LocatieStearsa == '1'" style="font-weight: bold !important; margin-right: 5px"  type="danger">Locatie stearsa</el-tag>
                                    
                                    <el-tag  style="font-weight: bold !important; " :type="Info.NrLuniNeplatite > 3 ? 'danger' : 'success' ">{{Info.NrLuniNeplatite}} luni neplatite</el-tag>

                                    <el-tag  v-if="Info.NrLuniNeplatiteMaiVechi > 0" style="margin-left: 5px;font-weight: bold !important; " type="danger">{{Info.NrLuniNeplatiteMaiVechi}} luni neplatite mai vechi de {{LocatieSelectata.NrMaximLuniNeplatite}} luni</el-tag>

                                    <el-tag v-if="Info.NrPasuiriConsecutive > 0" style="font-weight: bold !important;  animation: blink 1s linear infinite; margin-left: 5px;" :type="Info.NrPasuiriConsecutive > 2 ? 'danger' : 'warning' ">
                                        {{Info.NrPasuiriConsecutive}} păsuiri consecutive 
                                        <span v-if="Info.NrPasuiriConsecutive > 2">!!! !!!</span>
                                        <span v-if="Info.NrPasuiriConsecutive > 4">!!! NESERIOS !!!</span>
                                    </el-tag>
                                    

                                    <tag-expirare v-if="StatieSelectata.DataExpirarii != '' " style="margin-left: 5px;" :type="StatieSelectata.StatusExpirare">
                                        Valabil {{StatieSelectata.ZilePanaLaExpirare}} zile pana la data de {{StatieSelectata.DataExpirarii | date_nice_format}}
                                    </tag-expirare>
                                    


                                </el-form-item>

                                <el-form-item label='Data expirarii' >
                                    <el-date-picker 
                                        v-model='Filters.DataExpirarii' 
                                        class='full-width' type='date' 
                                        value-format='yyyy-MM-dd' 
                                        format='dd.MM.yyyy' 
                                        :picker-options="pickerOptions"
                                    />
                                </el-form-item>

                                <el-row :gutter='10' v-if="Info.ArePasuiri">
                                    <el-col :span='4'>
                                        <el-button class='full-width' size='small' @click='addtime_max()'>Max</el-button>
                                    </el-col>
                                    <el-col :span='5'>
                                        <el-button class='full-width' size='small' @click='addtime("2d")'>+2 zile</el-button>
                                    </el-col>

                                    <el-col :span='5'>
                                        <el-button   class='full-width' size='small' @click='addtime("3d")'>+3 zile</el-button>
                                    </el-col>

                                    <el-col :span='5'>
                                        <el-button class='full-width' size='small' @click='addtime("4d")'>+4 zile</el-button>
                                    </el-col>

                                    <el-col :span='5'>
                                        <el-button class='full-width' size='small' @click='addtime("5d")'>+5 zile</el-button>
                                    </el-col>
                                </el-row>

                                <el-row :gutter='10' v-else>
                                    <el-col :span='4'>
                                        <el-button class='full-width' size='small' @click='addtime_max()'>Max</el-button>
                                    </el-col>
                                    <el-col :span='5'>
                                        <el-button class='full-width' size='small' @click='addtime("1w")'>+1 sapt</el-button>
                                    </el-col>

                                    <el-col :span='5'>
                                        <el-button  :disabled="!AdaugareDataExtinsaEnabled" class='full-width' size='small' @click='addtime("1m")'>+1 luna</el-button>
                                    </el-col>

                                    <el-col :span='5'>
                                        <el-button  :disabled="!AdaugareDataExtinsaEnabled" class='full-width' size='small' @click='addtime("3m")'>+3 luni</el-button>
                                    </el-col>

                                    <el-col :span='5'>
                                        <el-button  :disabled="!AdaugareDataExtinsaEnabled" class='full-width' size='small' @click='addtime("1y")'>+1 an</el-button>
                                    </el-col>
                                </el-row>

                                <hr/>
                                <el-button class='full-width' type='primary' native-type='submit' >Genereaza</el-button>
                                <hr/>
                            </div>
                </el-form>

            </div>


        </el-card>

        <taskuri-dialog ref="dlg-taskuri"/>

       
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import TagExpirare from '@/widgets/TagExpirare';
import eventBus from '@/backend/EventBus';
import Taskuri_dialog from '@/pages/locatii/Taskuri_dialog.vue';
import moment from 'moment';

var GlobalAdaugareDataExtinsaEnabled = true;

export default {
    name: "licente",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
        'tag-expirare': TagExpirare,
        'taskuri-dialog': Taskuri_dialog
    },
    data () {
        return {
            filtru_clienti_disabled: false,
            Results: [],
            base_url: '',
            CautareCod:'',
            Filters: { 
                IdClient:   '-1',
                IdLocatie:  '-1',
                IdStatie:   '-1',
                DataExpirarii: moment().format("YYYY-MM-DD")
            },
            hideInfoClienti: false,
            hideInfoFull: false,
            Info: {
                clienti: [],
                locatii: [],
                statii:  [],
                NrLuniNeplatite: 0,
                NrLuniNeplatiteMaiVechi:0,
                NrPasuiriConsecutive: 0,
                NrTaskuriDeschise: 0,
                ArePasuiri: false
            },
            pickerOptions:{
                disabledDate(time) {
                    var mom = moment( time );
                    if( GlobalAdaugareDataExtinsaEnabled ) return false;
                    var after1week = moment().add("1","week");
                    return mom.isAfter(after1week);
                }
            },
            StatieSelectata: { Id: "", Nume: "", DataExpirarii: "", ZilePanaLaExpirare: 0, OperatInFacturier: '0', LocatieStearsa: '0'}, 
            LocatieSelectata: { Id:"", Nume:"", NrMaximLuniNeplatite: "2", OperatInFacturier: "0", Sters: "0"},
            CodStatie: '',
            CodGenerat: '',
            AdaugareDataExtinsaEnabled: true
        }
    },
    methods: {

         get_nume_locatie(){
            var locatie = this.Info.locatii.find( x=> x.Id == this.Filters.IdLocatie );
            return  locatie != null  ? locatie.Nume : "-";
        },

         get_nume_statie(){
            var statie = this.Info.statii.find( x=> x.Id == this.Filters.IdStatie );
            return  statie != null  ? statie.Nume : "-";
        },

        async get_info() {
            this.filtru_clienti_disabled = settings.is_client_selectat_activ();

            var response        = await this.post("LicenteDashboard/get_info");
            this.Info.clienti   = response.clienti;
            this.Info.clienti.push( { Id: "-1", Nume: 'Toti clientii' } );

            if( settings.is_client_selectat_activ() ){
                this.Filters.IdClient = settings.get_id_client_selectat();
            }

            await this.on_change_client();

        },

        async alege_client_selectat() {
            var id    = this.Filters.IdClient;
            var nume  = "";
            var useri = this.Info.clienti.filter( x => x.Id == id );
            if( useri.length > 0 ) { nume = useri[0].Nume; }

            settings.set_id_client_selectat  ( id    );
            settings.set_nume_client_selectat( nume  );
            eventBus.$emit('change-client-event');
            
            this.filtru_clienti_disabled = true;

        },

        addtime_max(){
            var locatie = this.Info.locatii.find( x => x.Id == this.Filters.IdLocatie);
            if( locatie != null ) {
                if( this.AdaugareDataExtinsaEnabled )
                    this.Filters.DataExpirarii = moment().add(locatie.NrZileMaximLicentiereManuala, "days").format("YYYY-MM-DD");
                else
                    this.Filters.DataExpirarii = moment().add(7, "days").format("YYYY-MM-DD");
            } else {
                this.$message('Alegeti o locatie!');
            }
        },

        addtime(interval){
            if( interval == '2d' ){
                this.Filters.DataExpirarii = moment().add("2", "days").format("YYYY-MM-DD");
            }
            if( interval == '3d' ){
                this.Filters.DataExpirarii = moment().add("3", "days").format("YYYY-MM-DD");
            }
            if( interval == '4d' ){
                this.Filters.DataExpirarii = moment().add("4", "days").format("YYYY-MM-DD");
            }
            if( interval == '5d' ){
                this.Filters.DataExpirarii = moment().add("5", "days").format("YYYY-MM-DD");
            }

            if( interval == '1w' ){
                this.Filters.DataExpirarii = moment().add("1", "week").format("YYYY-MM-DD");
            }
            if( interval == '1m' ){
                this.Filters.DataExpirarii = moment().add("1", "month").format("YYYY-MM-DD");
            }
            if( interval == '3m' ){
                this.Filters.DataExpirarii = moment().add("3", "months").format("YYYY-MM-DD");
            }
            if( interval == '1y' ){
                this.Filters.DataExpirarii = moment().add("1", "year").format("YYYY-MM-DD");
            }
        },

        async copiaza(){
            var copyText = document.querySelector("#cod-generat");
            copyText.select();
            document.execCommand("copy");
            this.$message('Am copiat ' + copyText.value);
        },

        async on_change_client( recursiveChange = true ){
            this.CodGenerat         = "";
            this.hideInfoClienti    = true;
            var response            = await this.post("LicenteDashboard/on_change_client", {idClient: this.Filters.IdClient});
            this.Info.locatii       = response.locatii;
            // this.Info.locatii.push( {Id: "-1", Nume: "Toate locatiile"});
            
            if( response.locatii.length > 0 ) {
                this.Filters.IdLocatie = response.locatii[0].Id;
                if( recursiveChange ) {
                    await this.on_change_locatie();
                }
            }
            this.hideInfoClienti    = false;
        },

        async on_change_locatie( recursiveChange = true ){
            this.CodGenerat         = "";
            var response            = await this.post("LicenteDashboard/on_change_locatie", { idClient: this.Filters.IdClient, idLocatie: this.Filters.IdLocatie });

            var locatieSelectata  = this.Info.locatii.filter( x=> x.Id == this.Filters.IdLocatie );
            this.LocatieSelectata           = locatieSelectata[0];

            this.Info.statii                = response.statii;
            this.Info.NrLuniNeplatite       = response.NrLuniNeplatite;
            this.Info.NrPasuiriConsecutive  = response.NrPasuiriConsecutive;
            this.Info.NrTaskuriDeschise     = response.NrTaskuriDeschise;
            this.Info.ArePasuiri            = response.ArePasuiri == '1';
            this.Info.NrLuniNeplatiteMaiVechi = response.NrLuniNeplatiteMaiVechi;


            this.AdaugareDataExtinsaEnabled  = ( this.LocatieSelectata.NrMaximLuniNeplatite - this.Info.NrLuniNeplatite > 0 ) && this.Info.NrLuniNeplatiteMaiVechi == 0;
            GlobalAdaugareDataExtinsaEnabled = this.AdaugareDataExtinsaEnabled;

            if( this.Info.statii.length > 0 )
            {
                this.Filters.IdStatie   = this.Info.statii[0].Id;
                this.CodStatie          = this.Info.statii[0].Cod;
                
                if( recursiveChange ) {
                    await this.on_change_statie();
                }
            }
        },

        async on_change_statie(){
            this.CodGenerat      = "";
            var statieSelectata  = this.Info.statii.filter( x=> x.Id == this.Filters.IdStatie );
            this.StatieSelectata = statieSelectata[0];
            this.CodStatie       = statieSelectata.Cod;
        },

        extrage_cod(text){
            var ret   = text.toString().trim();
            ret       = ret.replace(":"," ");
            ret       = ret.replace(","," ");
            ret       = ret.replace("."," ");
            ret       = ret.replace(";"," ");
            var parts = ret.split(" ");
            //daca lungimea >= 15 asta e codul
            for (let i = 0; i < parts.length; i++) {
                const cuvant = parts[i];
                if( cuvant.length >= 15 ) {
                    ret = cuvant.trim();
                }
            }
            return ret;
        },

        async paste_and_search() {
            var text = await navigator.clipboard.readText();
            //curatam codul de alte cuvinte
            text = this.extrage_cod(text);
            //
            this.CautareCod = text;
            this.cautare_dupa_cod();
        },

        show_taskuri_dialog() {
            this.$refs['dlg-taskuri'].show_me(this.LocatieSelectata.Id);
        },

        async cautare_dupa_cod(){
            //
            this.CodGenerat = "";

            var response = await this.post("LicenteDashboard/cautare_dupa_cod", {cod: this.CautareCod});
            if( !response.Error ){
                this.Filters.IdClient   = response.IdClient;
                await this.on_change_client(false);
                this.Filters.IdLocatie  = response.IdLocatie;
                await this.on_change_locatie(false);
                this.Filters.IdStatie   = response.IdStatie;
                await this.on_change_statie(false);
            } else {
                this.$message.error(response.ErrorMessage);
                //resetam
                this.Filters.IdClient   = '-1';
                this.Filters.IdLocatie  = '-1';
                this.Filters.IdStatie   = '-1';
                await this.on_change_client(false);
            }
        },

        async genereaza_cod() {
            if( this.Filters.DataExpirarii == null || this.Filters.DataExpirarii == ""){
                this.$message.error("Data este goala!");
                return;
            }
            if( this.Filters.IdStatie == '-1' ){
                this.$message.error("Alegeti o statie corecta!");
                return;
            }
            //verificam serverside

            var responseVerificare = await this.post("LicenteDashboard/verifica_inainte_de_generare", { IdStatie: this.Filters.IdStatie } );
            if( responseVerificare.Atentionare ) {
                var confirm = await this.$confirm( responseVerificare.Mesaj, 'Atentie');
                if( !confirm )
                {
                    return;
                }
            }


            var response = await this.post("LicenteDashboard/genereaza", { IdStatie: this.Filters.IdStatie, ExpirationDate: this.Filters.DataExpirarii } );
            if( !response.Error ) {
                console.log(response);
                this.CodGenerat = response.Cod;
            } else {
                this.$message.error(response.ErrorMessage);
            }
        }
        
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.select_menu_item('licente');

        if( !this.$has_right("pagina_de_licente") )  this.$router.push("/no-rights");

        eventBus.$on('clear-client-event', () => {
            this.filtru_clienti_disabled = settings.is_client_selectat_activ();
        });

        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .rezultat{
        background-color: #f0f9eb;
        color: #67C23A;
        padding: 8px 16px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 25px;
        text-align: center;
        .rand{
            display: flex;
            width: 100%;
            .col-1{
                width: 80%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }
            .col-2{
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .el-form-item{
        margin-bottom: 0px !important;
    }

    .el-date-editor{
        font-size: 28px !important;
        .el-input--prefix .el-input__inner{
            padding-left: 50px !important;
        }
    }
    
    

</style>
