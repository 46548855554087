<template>
    <el-tag :size="size" :class="type">
        <slot></slot>
    </el-tag>
</template>
    
<script>
    
    export default {
        name: 'TagExpirare',
        props: {
            size: {
                type: String,
                default: "default"
            },
            type: {
                type: String,
                default: "neexpirat"
            }
            //expirat | neexpirat | urmeaza
        },
        data () {
            return {
            }
        },
        methods:{
        }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="less">
        .expirat{
            background: black !important;
            color: white !important;
        }

        .neexpirat{
            color: black;
            background: white;
        }

        .urmeaza{
            background: yellow !important;
            color: black;
        }
    
    </style>
    